img {
    width:100%;
    border-radius:0.2em;
    background-color: #8ec5fc88;
}


.page {
    min-height: 0;
    width: 100vw;
    max-width: 100%;
    max-height: 100%;
    height:95vh;
    flex-direction: column;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 1 auto;
}

h1 {
    text-align: left;
    margin-top:1em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 8em;
    margin-left:2vw;
}

h2 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-top:solid 4px #161d6d;
    margin-left:30%;
    margin-right:30%;
    padding-top: 1em;
    font-size:2em;
    
}

hr{

    background-color:#4b4b4b;
    height:1em;
}

.img-card {

    width:25vw;
    min-width:270px;
    border-radius:0.5em;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow:  5px 5px 10px #434c5e;
    z-index: 4;
}


.separator {
    width: 100%;
    height:6vh;
    box-shadow: 0px 10px 10px 0px #4048a744;
}

figcaption {
    text-align: center;
    font-size:1em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.sectionParagraphe-row {
    display:flex;
    flex-direction: row;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size:1.5em;
  
    
}

.sectionParagraphe-column {
    display:flex;
    flex-direction: column;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size:1.5em;
    width:90%;
    margin:auto;
    align-items: left;
}

.sectionParagraphe-column p, .sectionParagraphe-row p {
    width:50%;
    font-size:1.2em;
    padding-left: 4em;

}

.important {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600 !important;
    border-radius: 1em;

    border:solid;
    border-color:#b2cdff7a;
    border-width:thin;
}


.information {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300 !important;

}

.information, .important {
    border-radius: 1em;

    padding:0.2em;
    padding-left: 2vw;
    padding-right: 2vw;
    box-shadow:  25px 25px 50px #7283a385,
             -25px -25px 50px #b2cdff83;

    margin:auto;
    margin-top:2em;
    margin-bottom:2em;
    width:65%;
}


.information p, .important p {
    width:100%;
    padding-left: 0;
}

.logo {
    position:absolute;
    width:10vmax;
    top:10px;
    right:10px;
}
.img-wrapper {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.nextButton {
    border-radius: 5em;
    color:#242d88;
    font-size: 2em;
    font-family: 'Montserrat';
    font-weight: 600 !important;
    background-image: linear-gradient(90deg, #8EC5FC 0%, #E0C3FC 100%);
    height:5vh;
    display:flex;
    padding:1vw;
    
    box-shadow:  25px 25px 50px #7283a3,
    -25px -25px 50px #b2cdff;
    margin:1em;
    text-decoration: none;
    display: inline-block;
    text-align: center;
  }

#clickCube{
    position:absolute;
    background: #ffffffaf;
    border-radius: 0.5em;
    right: 65%;
    max-width: 90%;
    bottom: 10%;
    width: 40vw;
    height:5vw;
    transform:translate(50%,50%);
    font-size:1.5em;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    max-height: 90vh;
    z-index: 9;
    display: flex;
    flex-direction: column;
}
#homepage{
    position: fixed;
    min-height : 0;
    width: 100vw;
    max-width:100%;
    top:5%;
    max-height:100%;
    height:95%;
    flex-direction: row;
    margin: 0;
    padding: 0;
    display: flex;
    flex:1 1 auto;
}

#sideLanding{
    width:30%;
    padding:5%;
    background-image: linear-gradient(90deg, #8EC5FC 0%, #E0C3FC 100%);
    box-shadow: -4px 0px 10px #4f769c;
    z-index: 2;
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: center;
}

#sideLandingContent {
    margin:auto;
    display: flex;
    flex-direction: column;
}
#sideLanding h1 {
    font-size:4em;
    margin:0%;
    text-align: left;
}
#sideLanding h2 {
    margin:0%;
    text-align: left;
    color:#242b38;
}
#sideLanding p {
    font-size:1.5em;
}

.nextButton a:hover {
    color:#010814;
    background-image: linear-gradient(90deg, #8EC5FC 40%, #E0C3FC 60%);
}

.img-card:hover
{
    transform: scale(1.1);
}

.centered
{
    align-items: center;
}

@media only screen and (max-device-width: 768px) {

    .information, .important {

        width:80%;
    }

    .sectionParagraphe-column p, .sectionParagraphe-row p {
        width:100%;
        padding-left: 0em;
    }
    
    .img-card {

        width:90vw;
        min-width:60vw;
    }
    .logo {
        width:5vmax;
    }

    h1 {
        font-size: 3em;
    }

    h2{
        font-size:2em;
        margin-left:3%;
        margin-right:3%;
    }
    .img-wrapper {
        justify-content: space-evenly;
        display: flex;
        flex-direction: column;
    }
    .nextButton {
        border-radius: 5em;
        padding-top:2em;
        padding-bottom:2em;
        height:20vh;
      }
    
    #clickCube{
        width: 80vw;
        height:10vw;
        font-size:1em;
        right: 50%;
    }
    #homepage{
    flex-direction: column-reverse;
    }
    #sideLanding{
        width:90%;
        height:50%;
    }
    #sideLanding h1 {
        font-size:2em;
    }
    #sideLanding h2 {
        font-size:1.3em;
    }
    #sideLanding p {
        font-size:1em;
    }
}
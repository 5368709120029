.Header{
    margin:auto;
    z-index:10;
    width:100%;
    overflow:hidden;
    display:flex;
    background-color: #8EC5FC;
    background-image: linear-gradient(90deg, #8EC5FC 0%, #E0C3FC 100%);
    box-shadow: 4px 4px 10px #4f769c;
    align-items: center;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    position: fixed;
    top:0;
    left:0;
    padding:0;
    height:5%;
    min-height:5%;
}

.contentHeader{
    flex-direction:row;
    display:flex;
    flex-grow:1;
}

.logoHeader {
    height:100%;
    width:auto;
}

.Header ul {
    display:flex;
    flex-direction: row;
    flex: auto;
    padding:0;
    align-items:center;
    justify-content: left;
}

.Header button
{
    border:0;
    color:#161d6d;
    background-color: transparent;
    font-size:1em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.Header button:hover
{
    color:white;
    cursor:pointer;
}

.Header li {
    margin: 0.1em;
    display:flex;
    font-size:1.2em;
    height: 100%;
    text-align: center;
    border-left:solid 2px #161d6d8f;
    padding-left:5px;
    margin-left:10px;
}


.Header a{
    text-decoration: none;
    
    color:#161d6d;
    margin:auto;
    
}

.Header a:hover{
    background-color: transparent; 
    
}

a:hover{
    color:white;
    
}

.Header span {
    color:#161d6d;
    display:flex;
    text-align: right;
    margin-right:5vmax;
    margin:auto;
    cursor: default;
    font-size:1em;
}


.Modal {
    background: #ffffffaf;
    border-radius: 0.5em;
    box-shadow: 0 10px 20px rgba(black, 0.2);
    right: 25%;
    max-width: 90%;
    pointer-events: all;
    position: absolute;
    top: 10%;
    width: 40vw;
    text-align: left;
    max-height: 90vh;
    z-index: 10;
    display: flex;
    flex-direction: column;

}


.ModalForm {

    z-index: 10;

}

.Modal p 
{
    margin-left:1em;
}
.Modal h3 {
    margin-left:0.5em;
    margin-bottom:0;
}

.Modal .separator {
    height:1vh;
}

#btnCloseContact
{
    z-index:11;
    position: absolute;
    right: 50%;
    top: 12%;
    transform:translate(50%,50%);
    height:2.5vh;
    font-size:2vh;
}


@media only screen and (max-device-width: 768px) {
    
    #btnCloseContact
    {
        top: 5%;
    }

    .Header li {
        font-size:0.9em;
        margin: 0.1em;
        padding-left:1px;
        margin-left:4px;
    }

    .Header ul {
        margin-top:5px;
        margin-bottom:2px;
        
    }
    .logoHeader {
        height:80%;
        width:auto;
    }
    .Header{
        flex-wrap:wrap;
        height:7%;
        padding-bottom:2px;
    }
    .Modal {
        width: 60vw;
    }
    
    .contentHeader{
        flex-direction:column;
    }


}

:root {
  --bg-color: #92A8D1;
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  color:#161d6d;
}
#root{
  display:flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  padding: 0;
  height: 100%;
  max-width:100%;
  max-height:100%;
}

strong {
  display:inline !important;
  background-color: #ffffff2d;
  border-radius: 0.25em;
  border: 0.1em solid #e4e7ff;
  margin-left: 0.2em;
  margin-right: 0.2em;
  
}

body {
  background-color: var(--bg-color);
  padding-top:4%;
  overflow-x:hidden;
  margin: 0;
  font-family: 'Roboto Slab',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#uvMapper {
  position: fixed;
  min-height : 0;
  width: 100vw;
  max-width:100%;
  top:5%;
  max-height:100%;
  height:95%;
  flex-direction: row;
  margin: 0;
  padding: 0;
  display: flex;
  flex:1 1 auto;
}

#canvas-container{
  padding-top:100%;
  width:80%;
  margin: 0;
  padding: 0;
  order:0;
  float:left;

}

#side
{
  
  font-family: "Montserrat";
  font-weight: 500;
  display: flex;
  flex-direction: column;
  height:auto;
  width:20%;
  padding: 0;
  order:1;
  z-index:3;
  flex:1;
  overflow: hidden; 
  justify-content: space-between;
  background-color: #8EC5FC;
  background-image: linear-gradient(90deg, #8EC5FC 0%, #E0C3FC 100%);
  box-shadow: -4px -4px 10px #4f769c,
  4px 4px 10px #4f769c,
  4px 4px 10px #4f769c;


}




#canvas{
  background-color: #92A8D1;
  aspect-ratio: 1;
  margin:2%;
  border-radius: 2%;
  order:0;
  min-width: auto;
  overflow: hidden;   
    box-shadow: inset -4px -4px 10px #8EC5FC ,
   4px 4px 10px #4f769c,
   -4px -4px 10px #4f769c,
  inset  4px 4px 10px #8EC5FC ;
}

.separatorSide {
  width: 100%;
  height:1vh;
  margin-top:1em;
  box-shadow: 0px 10px 10px 0px #4048a744;
 
}

#canvas2D{
  width: 98%;
  height:98%;
  margin:1%;

}
#ToolSidepanel{
  order:1;
  margin:1%;
  min-width: auto;
  flex-grow: 1;
  display: flex;
  flex-direction:column;
}

#ToolsMenu{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#ToolsContent {
  display:flex;
  flex-direction: column;
  align-items: center;
}

#Stats,#Goals{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}

#StatsContent{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

#StatsContent span{

  font-size: 1.5vw !important;
}

#StatsContent #GoalExplain
{
  font-size:1vw !important;
  margin-left:1vw;
  margin-bottom:2vh;
}

#StatsTitle, #GoalsTitle,#ToolTitle{
  flex:0 0 90%; 
  font-size:1.5vmax;
  margin:1em;
}

#ToolUnfold, #ToolSew, #ToolCut{
  flex:0 0 90%;
}

#ToolSidepanel span {
  font-size:1vmax ;
}

.insideCanvas {
  position:fixed;
  z-index: 20;
  border-radius: 50%;
  top:6%;
  left:1em;
  border:0;
  width:4em;
  color:#161d6d;
  background-color: transparent;
}

.insideCanvas:hover {
  transform: scale(1.1);
}

.insideCanvas:active {
  transform: scale(0.9);
}


.insideCanvas img {
  background-color: transparent;
}

.offset{
  left:5em;
}
.btnPrimary, .btnBlocked{
  flex:0 0 40%;
  margin : 5px;
  height:auto;
  width:8vmax;
  font-family: "Montserrat";
  font-weight: 300;
  font-size:1.5vmax;
  color: #fff;
  cursor: pointer;

  text-align:center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}


.btnPrimary:hover {
  transform: scale(1.1);
}

.btnPrimary:active {
  transform: scale(0.9);
}


.btnBlocked
{
  pointer-events: none;
  color:#aaaaaa;
  background-image: linear-gradient(90deg, #d7e7f7 0%, #efe3fa 100%);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.15);
}


form
{
  position:absolute;
  margin: 0px auto;
  width: 100%;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  width:30vw;
  height:70vh;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2em;
  padding-bottom:2em;

  background: linear-gradient(153deg, rgba(255,255,255,0.76234243697479) 0%, rgba(255,255,255,0.4290091036414566) 35%, rgba(255,255,255,0.19371498599439774) 100%);
  box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(15px);

}

p {
  line-height: 160%;
}

form h2 {
  border-top:0;
}
form div 
{
  width:100%;
}

form input, form textarea, form button
{
  border: 0;
  width:90%;
  border-radius: 0.4em;
  background-color: rgba(215, 221, 253, 0.493);
  margin:1em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
form button
{
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  height:2em;
  color:white;
}
input:focus,form textarea:focus {outline:none!important;}

form .Message
{
  display: flex;
  flex-grow:1;
}



@media only screen and (max-device-width: 768px) {

  body {
    padding-top: 7%;;
  }
  .desktopOnly{
    visibility: hidden;
    height:0;
  }


  #side
  {
    flex-direction: row;
    height:40%;
    width:100%;
  }

  #uvMapper {
    flex-direction: column;
    top:7%;
    height:93%;
  }
  #ToolSidepanel {
    width:50%;
  }

  #ToolSidepanel span {
    font-size:1vmax ;
  }

  #ToolsContent {
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  #canvas-container{
    padding-top:100%;
    height:60%;
    width:100%;
    margin: 0;
    padding: 0;
    order:0;
    float:left;
  
  }
  form
  {
    width:90vw;
    height:80vh;
  }
  .btnPrimary, .btnBlocked
  {
    font-size:0.9em;
    height:3vmax;
    width:10vmax;
  }
  
  #Stats,#Goals{
    margin:0;
    flex-direction: row;
  }
  
  .btnSpan
  {
    display:flex;
    flex:1 1 auto;
  }

  #StatsTitle, #GoalsTitle,#ToolTitle{
    margin:0.1em;
  }
  #StatsContent #GoalExplain
  {
    font-size:1.2vh !important;
    margin-bottom:0;
  }
  #StatsContent span {
    font-size:1.5vh !important;
    margin-top: 0.5vh;
  }

  .separatorSide{
    margin:0.1em;
  }

  strong {
    margin-left: 0.1em;
    margin-right: 0.1em;
    
  }

  .insideCanvas {
    top:8%;
  }
}